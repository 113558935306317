<template>
  <div class="index">
    <!-- 左侧 -->
    <div class="l">
      <!-- 容器 -->
      <div class="l-box">
        <!-- 图片 -->
        <img src="/fufeizixun/imgs/logo.png" alt="" />
        <!-- 文字 -->
        <p>身边云企业服务中心</p>
      </div>
    </div>

    <!-- 右侧 -->
    <div class="r">
      <!-- 上层标题 -->
      <p>
        <svg
          style="color: #108ee9"
          viewBox="64 64 896 896"
          data-icon="sound"
          width="1em"
          height="1em"
          fill="currentColor"
          aria-hidden="true"
          focusable="false"
          class=""
        >
          <path
            d="M625.9 115c-5.9 0-11.9 1.6-17.4 5.3L254 352H90c-8.8 0-16 7.2-16 16v288c0 8.8 7.2 16 16 16h164l354.5 231.7c5.5 3.6 11.6 5.3 17.4 5.3 16.7 0 32.1-13.3 32.1-32.1V147.1c0-18.8-15.4-32.1-32.1-32.1zM586 803L293.4 611.7l-18-11.7H146V424h129.4l17.9-11.7L586 221v582zm348-327H806c-8.8 0-16 7.2-16 16v40c0 8.8 7.2 16 16 16h128c8.8 0 16-7.2 16-16v-40c0-8.8-7.2-16-16-16zm-41.9 261.8l-110.3-63.7a15.9 15.9 0 0 0-21.7 5.9l-19.9 34.5c-4.4 7.6-1.8 17.4 5.8 21.8L856.3 800a15.9 15.9 0 0 0 21.7-5.9l19.9-34.5c4.4-7.6 1.7-17.4-5.8-21.8zM760 344a15.9 15.9 0 0 0 21.7 5.9L892 286.2c7.6-4.4 10.2-14.2 5.8-21.8L878 230a15.9 15.9 0 0 0-21.7-5.9L746 287.8a15.99 15.99 0 0 0-5.8 21.8L760 344z"
          ></path>
        </svg>
        欢迎您访问身边云企业服务中心，登录账号或密码找回，请拨打客服电话
        <span>400-0051-667</span> 咨询。
      </p>

      <!-- 登录框 -->
      <div class="login-form">
        <!-- 表单 -->
        <form action="" class="ant-form ant-form-horizontal">
          <!-- 上层区块 -->
          <div class="l-s">
            <h2>立即注册</h2>

            <!-- input -->
            <div class="l-input">
              <i class="iconfont">
                <svg
                  style=""
                  viewBox="64 64 896 896"
                  data-icon="user"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                  focusable="false"
                  class=""
                >
                  <path
                    d="M858.5 763.6a374 374 0 0 0-80.6-119.5 375.63 375.63 0 0 0-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 0 0-80.6 119.5A371.7 371.7 0 0 0 136 901.8a8 8 0 0 0 8 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 0 0 8-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z"
                  ></path>
                </svg>
              </i>
              <input placeholder="请输入用户名" type="text" />
            </div>

            <!-- input -->
            <div class="l-input">
              <i class="iconfont">
                <svg
                  viewBox="64 64 896 896"
                  data-icon="lock"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                  focusable="false"
                  class=""
                >
                  <path
                    d="M832 464h-68V240c0-70.7-57.3-128-128-128H388c-70.7 0-128 57.3-128 128v224h-68c-17.7 0-32 14.3-32 32v384c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V496c0-17.7-14.3-32-32-32zM332 240c0-30.9 25.1-56 56-56h248c30.9 0 56 25.1 56 56v224H332V240zm460 600H232V536h560v304zM484 701v53c0 4.4 3.6 8 8 8h40c4.4 0 8-3.6 8-8v-53a48.01 48.01 0 1 0-56 0z"
                  ></path>
                </svg>
              </i>
              <input placeholder="请输入密码" type="text" />
            </div>

            <!-- 登录按钮 -->
            <div class="l-btn" @click="gores()">注册</div>
          </div>
        </form>
      </div>

      <!-- 信息层 -->
      <div class="r-info">
        <p>Copyright 2012-2020 Serviceshare.com Inc. All Rights Reserved.</p>
        <p>
          免费咨询：400-0051-667 咨询热线：010-65677932
          公司地址：北京市朝阳区建国路乙118号京汇大厦22层
        </p>
      </div>
    </div>

    <modal
      title="请付款阅读"
      btnType="1"
      modalType="middle"
      :showModal="showModal"
      @submit="showModal = false"
      @cancel="showModal = false"
    >
      <template v-slot:body>
        <img src="/imgs/pay.png" />
        <h2>微信支付：2元</h2>
      </template>
    </modal>
  </div>
</template>

<script>
// import navigation from "./../components/navigation";
import Modal from "./../components/Modal.vue";
export default {
  components: { Modal },
  data() {
    return {
      href: "index.html",
      ac1: [],
      ac2: [],
      ac3: [],
      ac: [],
      myData: [],
      mAc: [],
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.init();
    document.title = "身边云（北京）信息服务有限公司";
  },
  methods: {
    init() {
      this.axios.get("/mock/ac.json").then((res) => {
        const data = res.data;
        this.mAc = data.slice(0, 9);
      });
    },
    // 跳转 逻辑 封装
    goDays(methods, id) {
      this[methods](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
    },
    // 收费逻辑
    gopay() {
      alert("资讯每条2元，点击确定购买");
      this.showModal = true;
      // if (this.state == 0) {
      //   // this.$router.push("/pay");
      //   this.showModal = true;
      // }
      // // 未登录
      // if (this.state !== 0) {
      //   alert("请登录付费2元后可浏览！！！~");
      //   this.$router.push({ path: "/login", query: { k: 1 } });
      //   return;
      // }
    },
    gores() {
      alert("客服正在审核您的账号，请耐心等待！");
      window.location.href = "https://www.serviceshare.com/";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.index {
  padding: 0;
  margin: 0;
  height: 100%;
  overflow: hidden;
  height: 722.4px;
  display: flex;
  justify-content: space-between;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  // align-items: center;
  // 左侧
  .l {
    width: 39.7%;
    height: 100%;
    background-image: url("/fufeizixun/imgs/bj.jpg");
    display: flex;
    align-items: center;
    .l-box {
      width: 230px;
      height: 76px;
      margin: 0 auto;
      margin-top: -100px;

      img {
        // width: 100%;
        width: 230px;
        // height: 100%;
        height: 76.84px;
      }

      p {
        font-size: 26px;
        color: #fff;
        line-height: 2;
        width: 305px;
      }
    }
  }

  // 右侧
  .r {
    width: 60.3%;
    height: 100%;
    background: url("/fufeizixun/imgs/r.jpg") no-repeat top right;
    position: relative;
    p {
      font-size: 14px;
      margin-top: 16px;
      text-align: center;
      width: 100%;
      color: rgba(0, 0, 0, 0.65);
      line-height: 1.5;
      // letter-spacing: 1px;
      span {
        color: #108ee9;
      }
    }

    // 登录框部分
    .login-form {
      width: 429px;
      height: 295.8px;
      padding: 30px;
      box-shadow: 0 0 10px #ccc;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -215px;
      margin-top: -210px;
      z-index: 10;
      background-color: #fff;
      text-align: left;
      border-radius: 5px;
      // form表单
      .ant-form {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5;
        list-style: none;
        font-feature-settings: "tnum";

        // 上层
        .l-s {
          width: 100%;
          height: 50px;
          // 标题
          h2 {
            width: 136px;
            height: 51.4px;
            border-bottom: 2px solid #108ee9;
            margin: 0 auto;
            color: #108ee9;
            text-align: center;
            font-size: 28px;
            font-weight: 400;
            margin-bottom: 15px;
          }

          // input
          .l-input {
            width: 369px;
            height: 40px;
            // background-color: #c60023;
            display: flex;
            align-items: center;
            margin: 30px 0;
            padding-left: 30px;
            box-sizing: border-box;
            i {
              width: 10%;
              height: 100%;
              // background-color: #ff6600;
              border: 1px solid;
              position: relative;

              color: rgba(0, 0, 0, 0.65);
              font-weight: 400;
              font-size: 14px;
              text-align: center;
              background-color: #fafafa;
              border: 1px solid #d9d9d9;
              border-radius: 2px;
              transition: all 0.3s;
              line-height: 42px;
            }
            input {
              width: 90%;
              height: 100%;
              // background-color: #c60023;
              border: 1px solid #d9d9d9;
              transition: all 0.3s;
              color: #888;
              padding-left: 15px;
              box-sizing: border-box;

              &:hover {
                border: 1px solid #108ee9;
              }
            }
          }

          // 按钮
          .l-btn {
            width: 369px;
            height: 40px;
            background-color: #108ee9;
            color: #fff;
            font-size: 14px;
            text-align: center;
            line-height: 40px;
            cursor: pointer;
            margin-left: 30px;
          }
        }
      }
    }

    // 信息层
    .r-info {
      width: 100%;
      height: 50px;
      position: absolute;
      bottom: 15px;
      left: 0;
      p {
        font-size: 14px;
        color: #acb1b5;
        margin: 2px 0;
      }
    }
  }
}
</style>