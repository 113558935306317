<template>
  <div class="ac2" style="width: 100%">
    <!-- banner -->
    <div class="banner">
      <img src="/imgs/b3.jpg" alt="" />
    </div>

    <div class="container">
      <!-- 容器 -->
      <div class="a2-con">
        <h2>身边云（北京）信息服务有限公司</h2>
        <h4>Cloud around</h4>
        <div class="a2-box">
         
          <div class="l">
             <p>
              身边云创立于2019年，链接企业与共享服务的电子商务平台（服务即产品：
              SaaP Service-as-a-Product），简称“共享服务交易平台”，
              通过平台帮助企业快速使用共享服务，满足企业运营需求，提升整体经营效益。身边云通过技术手段将各类运营服务标准化、颗粒化，形成个人或小微团队以ServiceShare（共享服务）的方式完成该项工作解构并满足共享服务的订单。整合共享服务商供应链及第三方应用，赋能企业采用共享服务产品变革运营组织方式，构建企业共享服务开放平台。目前平台已超800种不定类型的订单的解构能力，可拆解项目种类超过3000+种。
            </p>

            <p>
              公司总部位于北京国贸CBD核心区，另在北京、上海、深圳、成都、西安等地设有分公司。自成立以来，已累计为57000+优质企业提供全方位的企业共享服务，帮助企业提升运营收益超10亿元。未来，身边云将持续整合共享服务生态链条，为企业提供更多共享服务解决方案，帮助企业提高经营收益。
            </p>
          </div>
           <div class="r">
            <img src="/imgs/usinfo.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/css/swiper.css";

export default {
  name: "ac1",
  components: {},
  data() {
    return {
      href: "index.html",
      ac1: [],
      ac2: [],
      ac3: [],
      ac: [],
      myData: [],
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.init1();
    document.title = "身边云（北京）信息服务有限公司";
  },
  methods: {
    init1() {
      this.axios.get("/mock/index2.json").then((res) => {
        const data = res.data;
        // 咨询区块1
        this.myData = data.splice(0, 3);
      });
    },
    // 跳转功能
    goDays(methodsWords, id) {
      this[methodsWords](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
    },
    // 收费逻辑
    gopay() {
      // console.log('1')
      // alert("资讯每篇2元，点击确定购买");
      // this.showModal = true;

      if (this.state == 0) {
        // this.$router.push("/pay");
        this.showModal = true;
        console.log(this.showModal);
      }
      // 未登录
      if (this.state !== 0) {
        alert("资讯每条2元，点击确定登录购买");
        this.$router.push({ path: "/login", query: { k: 1 } });
        return;
      }
    },
    msub() {
      // alert("支付失败！");
      this.$message.error("支付失败!");
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.ac2 {
  // margin-top: 65px;
  .banner {
    width: 100%;
    height: 300px;
    img {
      width: 100%;
      // height: 300px;
      height: 100%;
    }
  }

  .container {
    // 容器
    .a2-con {
      margin: 40px 0;
      h2 {
        font-size: 28px;
        color: #333;
      }
      h4 {
        font-size: 21px;
        color: #888;
      }

      .a2-box {
        width: 100%;
        height: 450px;
        @include flex();
        .l {
          width: 60%;
          height: 100%;
          // background-color: #ccc;
          border-bottom: 2px dashed #ccc;
          padding: 30px;
          box-sizing: border-box;
          p {
            width: 100%;
            font-size: 14px;
            line-height: 45px;
            text-indent: 30px;
          }
        }
        .r {
          width: 40%;
          height: 100%;
          // margin-left: 30px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
